import React, { useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import {
  maskCnpj,
  maskPhoneNumber,
  maskCep,
  maskCurrency,
} from '../utils/masks'
import { agencias } from '../../shared/form-data'

const PjForm = ({ register, errors, watch, setValue }) => {
  //Campo Select Estado
  const [estado, setEstado] = React.useState('')
  const handleEstado = (event) => {
    setEstado(event.target.value)
  }

  //Campo Select Cidade
  const [cidade, setCidade] = React.useState('')
  const handleCidade = (event) => {
    setCidade(event.target.value)
  }

  //Campo Select Agencia
  const [agencia, setAgencia] = React.useState('')
  const handleAgencia = (event) => {
    setAgencia(event.target.value)
  }

  //alerta Estado e Cidade
  function alertaAbrangencia() {
    alert(
      'O CredCaxias abrange somente o município de Caxias do Sul. Caso resida em outra cidade e tenha interesse em uma linha de crédito para sua empresa, consulte a agência Sicredi mais perto de você e verifique os programas disponíveis.',
    )
  }

  //Mascaras de inputs
  const cnpjValue = watch('Cnpj')
  useEffect(() => {
    setValue('Cnpj', maskCnpj(cnpjValue))
  }, [cnpjValue])

  const cepValue = watch('CepDaEmpresa')
  useEffect(() => {
    setValue('CepDaEmpresa', maskCep(cepValue))
  }, [cepValue])

  const phoneValue = watch('TelefoneDaEmpresa')
  useEffect(() => {
    setValue('TelefoneDaEmpresa', maskPhoneNumber(phoneValue))
  }, [phoneValue])

  const currencyValue = watch('FaturamentoAnual')
  useEffect(() => {
    setValue('FaturamentoAnual', maskCurrency(currencyValue))
  }, [currencyValue])

  //validação de preenchimento - errors
  const obrigatorio = { required: true }

  const cooperativa = watch(`Cooperativa`)

function getAgenciaPelaCooperativa(cooperativa){
  let agenciasFiltradas = agencias.filter(a => a.cooperativa == cooperativa);
  let listAgencias = [];
  agenciasFiltradas.forEach(a => {
    listAgencias.push(<MenuItem value={a.id}>{a.name}</MenuItem>);
  });
  return listAgencias;
}

  return (
    <div className="pjForm grid">
      <div className="Empresa">
        <TextField
          {...register('RazaoSocial', obrigatorio)}
          label="Razão Social"
          autoComplete="none"
          variant="filled"
          className="campoStyle"
        />
        {errors.RazaoSocial && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="CNPJ">
        <TextField
          {...register('Cnpj', obrigatorio)}
          label="CNPJ"
          autoComplete="none"
          variant="filled"
          className="campoStyle"
        />
        {errors.Cnpj && <span className="erroForm">&gt; Obrigatório</span>}
      </div>

      <div className="Telefone">
        <TextField
          {...register('TelefoneDaEmpresa', obrigatorio)}
          label="Telefone"
          autoComplete="none"
          variant="filled"
          className="campoStyle"
        />
        {errors.TelefoneDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="CEP">
        <TextField
          {...register('CepDaEmpresa', obrigatorio)}
          label="CEP"
          autoComplete="none"
          variant="filled"
          className="campoStyle"
        />
        {errors.CepDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="Endereco">
        <TextField
          {...register('EnderecoDaEmpresa', obrigatorio)}
          label="Endereço"
          variant="filled"
          className="campoStyle"
        />
        {errors.EnderecoDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="Bairro">
        <TextField
          {...register('BairroDaEmpresa', obrigatorio)}
          label="Bairro"
          variant="filled"
          className="campoStyle"
        />
        {errors.BairroDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <Box sx={{ maxWidth: 540 }} className="Estado">
        <FormControl variant="filled" fullWidth className="campoStyle">
          <InputLabel>Estado</InputLabel>
          <Select
            {...register('EstadoDaEmpresa', obrigatorio)}
            value={estado}
            label="Estado"
            onChange={handleEstado}
          >
            <MenuItem value={'RS'}>Rio Grande do Sul</MenuItem>
            <MenuItem value={'Outro'} onClick={alertaAbrangencia}>
              Outro
            </MenuItem>
          </Select>
        </FormControl>
        {errors.EstadoDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </Box>

      <Box sx={{ maxWidth: 540 }} className="Cidade">
        <FormControl variant="filled" fullWidth className="campoStyle">
          <InputLabel>Cidade</InputLabel>
          <Select
            {...register('CidadeDaEmpresa', obrigatorio)}
            value={cidade}
            label="Cidade"
            onChange={handleCidade}
          >
            <MenuItem value={'Caxias do Sul'}>Caxias do Sul</MenuItem>
            <MenuItem value={'Outro'} onClick={alertaAbrangencia}>
              Outro
            </MenuItem>
          </Select>
        </FormControl>
        {errors.CidadeDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </Box>

      <div className="E-mail">
        <TextField
          {...register('EmailDaEmpresa', obrigatorio)}
          label="E-mail"
          variant="filled"
          className="campoStyle"
        />
        {errors.EmailDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="Faturamento">
        <TextField
          {...register('FaturamentoAnual', obrigatorio)}
          label="Faturamento dos últimos 12 meses"
          variant="filled"
          className="campoStyle"
        />
        {errors.FaturamentoAnual && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <FormControl className="Porte radioStyle">
        <FormLabel>Porte da Empresa</FormLabel>
        <RadioGroup row defaultValue="MEI">
          <FormControlLabel
            {...register('PorteDaEmpresa', { required: true })}
            value="MEI"
            control={<Radio />}
            label="MEI"
          />
          <FormControlLabel
            {...register('PorteDaEmpresa', { required: true })}
            value="ME"
            control={<Radio />}
            label="ME"
          />
          <FormControlLabel
            {...register('PorteDaEmpresa', { required: true })}
            value="EPP"
            control={<Radio />}
            label="EPP"
          />
        </RadioGroup>
      </FormControl>

      <FormControl className="Atividade radioStyle">
        <FormLabel>Atividade</FormLabel>
        <RadioGroup row defaultValue={1}>
          <FormControlLabel
            {...register('AtividadeDaEmpresa', { required: true })}
            value="1"
            control={<Radio />}
            label="Industria"
          />
          <FormControlLabel
            {...register('AtividadeDaEmpresa', { required: true })}
            value="2"
            control={<Radio />}
            label="Comércio"
          />
          <FormControlLabel
            {...register('AtividadeDaEmpresa', { required: true })}
            value={3}
            control={<Radio />}
            label="Serviço"
          />
        </RadioGroup>
      </FormControl>

      <FormControl sx={{ minWidth: 450 }} className="Associado radioStyle">
        <FormLabel>Qual cooperativa pertence?</FormLabel>
        <RadioGroup row>
          <FormControlLabel
            {...register('Cooperativa', { required: true })}
            value="5"
            control={<Radio />}
            label="Sicredi"
          />
          <FormControlLabel
            {...register('Cooperativa', { required: true })}
            value="12"
            control={<Radio />}
            label="Cresol"
          />
           <FormControlLabel
            {...register('Cooperativa', { required: true })}
            value="18"
            control={<Radio />}
            label="Banco da família"
          />
        </RadioGroup>
        {errors.Cooperativa && (
          <span className="erroForm">
            <br />
            &gt; Obrigatório
          </span>
        )}
      </FormControl>
      
      {cooperativa == 5 && (

        <Box sx={{ maxWidth: 450 }} className="Agencia">
          <FormLabel className="personalColors">
            Selecionar agência onde possui conta e se não é associado, indicar
            agência que deseja se associar.
          </FormLabel>
          <FormControl variant="filled" fullWidth className="campoStyle">
            <InputLabel>Agência</InputLabel>
            <Select
              {...register('Agencia', obrigatorio)}
              value={agencia}
              label="Agencia"
              onChange={handleAgencia}
            >
             {getAgenciaPelaCooperativa(5)}
             
            </Select>
          </FormControl>
          {errors.Agencia && <span className="erroForm">&gt; Obrigatório</span>}
        </Box>
      )}
      {cooperativa == 12 && (
        <Box sx={{ maxWidth: 450 }} className="Agencia">
          <FormLabel className="personalColors">
            Selecionar agência onde possui conta e se não é associado, indicar
            agência que deseja se associar.
          </FormLabel>
          <FormControl variant="filled" fullWidth className="campoStyle">
            <InputLabel>Agência</InputLabel>
            <Select
              {...register('Agencia', obrigatorio)}
              value={agencia}
              label="Agencia"
              onChange={handleAgencia}
            >
           {getAgenciaPelaCooperativa(12)}
            </Select>
          </FormControl>
          {errors.Agencia && <span className="erroForm">&gt; Obrigatório</span>}
        </Box>
      )}
      {cooperativa == 18 && (
        <Box sx={{ maxWidth: 450 }} className="Agencia">
          <FormLabel className="personalColors">
            Selecionar agência onde possui conta e se não é associado, indicar
            agência que deseja se associar.
          </FormLabel>
          <FormControl variant="filled" fullWidth className="campoStyle">
            <InputLabel>Agência</InputLabel>
            <Select
              {...register('Agencia', obrigatorio)}
              value={agencia}
              label="Agencia"
              onChange={handleAgencia}
            >
           {getAgenciaPelaCooperativa(18)}
            </Select>
          </FormControl>
          {errors.Agencia && <span className="erroForm">&gt; Obrigatório</span>}
        </Box>
      )}
    </div>
  )
}

export default PjForm
