import React from 'react'
import Pessoa1 from '../assets/imgs/pessoa-1.png'
import Pessoa2 from '../assets/imgs/pessoa-2.png'
import Pessoa3 from '../assets/imgs/pessoa-3.png'
import Aviso from '../assets/imgs/ico-8.png'

const BcxSectionE = () => {
    return (
        <section id='sectionE'>
            <div className="container">
                <h2 className='title'>
                    Quem pode contar <br /><strong>com o CredCaxias</strong>
                </h2>

                <div className="micInd rowCard">
                    <div className="imagem">
                        <img src={Pessoa1} alt="Homem sorrindo e mão no queixo" />
                    </div>

                    <div className="texto">
                        <h3>Microempreendedor<br /><strong>Individual</strong></h3>
                        <p>com receita bruta anual igual <br /> ou inferior a R$ 81 mil</p>
                        {/* <p><strong>Limite de crédito:</strong><br />R$ 15 mil em 36 parcelas (sem carência) de até R$ 560,00<br />R$ 15 mil em 30 parcelas (06 meses de carência) de até R$ 690,00</p>
                        <p><strong>Custo da garantia:</strong><br />R$ 993,60</p> */}
                    </div>
                </div>

                <div className="micEmp rowCard">
                    <div className="imagem">
                        <img src={Pessoa2} alt="Mulher sorrindo de óculos" />
                    </div>

                    <div className="texto">
                        <h3><strong>Microempresa</strong></h3>
                        <p>com receita bruta anual igual <br /> ou inferior a R$ 360 mil</p>
                        {/* <p><strong>Limite de crédito:</strong><br />R$ 50 mil em 36 parcelas (sem carência) de até R$ 1850,00<br />R$ 50 mil em 30 parcelas (06 meses de carência) de até R$ 2290,00</p>
                        <p><strong>Custo da garantia:</strong><br />R$ 3.312,00</p> */}
                    </div>
                </div>

                <div className="peqEmp rowCard">
                    <div className="imagem">
                        <img src={Pessoa3} alt="Quatro pessoas sorrindo" />
                    </div>

                    <div className="texto">
                        <h3>Pequenas<br /><strong>Empresas</strong></h3>
                        <p>Faturamento anual entre <br /> R$ 360 mil e R$ 4,8 milhões</p>
                        {/* <p><strong>Limite de crédito:</strong><br />R$ 100 mil em 36 parcelas (sem carência) de até R$ 3.680,00<br />R$ 100 mil em 30 oarcelas (06 meses de carência) de até R$ 4.570,00</p>
                        <p><strong>Custo da garantia:</strong><br />R$ 6.624,00</p> */}
                    </div>
                </div>

                <div className="aviso">
                    <div className="imgAviso">
                        <img src={Aviso} alt="Icone Balão com Exclamação dentro" />
                    </div>

                    <div className="textoAviso">
                        <p>• Crédito sujeito a análise de crédito. Parcelas simuladas sem IOF.<br />• Terão acesso a linha associados que apresentarem no mínimo <strong>01 ano de faturamento <br />completo ,</strong> declarados nos documentos indicados na documentação necessária/abaixo.</p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default BcxSectionE