import React from 'react'
import logoCredCaxias from '../assets/imgs/logo.png'
import logosC from '../assets/imgs/logosc.png'

const BcxSectionA = () => {
    return (
        <section id="SectionA">
            <div className="container">
                <div className="text">
                    <p>O CredCaxias é uma solução para auxiliar prestadores de serviços e micro e pequenas empresas a superar as dificuldades geradas pela pandemia da covid-19.</p>
                    <p>O objetivo é facilitar acesso ao crédito, além de proporcionar ferramentas de gestão, vendas e marketing.</p>
                    <p>Uma união entre a Prefeitura de Caxias do Sul, Sebrae, Sicredi Pioneira e RS Garanti para retomar empregos e recuperar a economia local, na fase II, o Cresol se une ao programa e agora na fase III, com o reforço do Banco da Família buscam amenizar a situação das empresas locais devido as enchentes, para juntos recuperarmos nossa cidade.</p>
                </div>

                <div className="logos">
                    <img className="credCaxias" src={logoCredCaxias} alt="Logotipo da Cred Caxias" />
                    <img className="parceiros" src={logosC} alt="Logotipos Sicredi Sebrae Cresol RS Garanti Banco da familia Prefeitura de Caxias do Sul" />
                </div>
            </div>
        </section>
    )
}

export default BcxSectionA