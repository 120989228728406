import React from 'react'
import Sebrae from '../assets/imgs/sebrae.png'

const BcxSectionD = () => {
    return (
        <section id='sectionD'>
            <div className="container">
                <div className="header">
                    <div className="titulo">
                        <img src={Sebrae} alt="Logo da Sebrae" />
                        <h2>
                            Uma Parceria com <br /><strong>soluções para sua empresa</strong>
                        </h2>
                    </div>
                    <div className="descricao">
                        <p>O Sebrae está oferecendo 2 capacitações online GRATUITAS e 2 consultorias online aos empreendedores interessados.</p>
                        <p>Mesmo sem efetivação do crédito, você pode ter acesso a ferramentas que te ajudam a gerenciar melhor seus negócios.</p>
                        <p><strong>Aproveite essa oportunidade!</strong></p>
                    </div>
                </div>

                <div className="content">
                    <div className="col">
                        <h3>Estratégia – 6h</h3>
                        <a className="btn" href="https://digital.sebraers.com.br/produtos/estrategia/curso/planejamento-para-inovar/" target="_blank" rel="noopener noreferrer">Planejamento para inovar</a>

                        <h3>Inovação – 3h</h3>
                        <a className="btn" href="https://digital.sebraers.com.br/produtos/pessoas/curso/neurolideranca-nas-organizacoes/" target="_blank" rel="noopener noreferrer">Neuroliderança nas organizações</a>

                        <h3>Marketing e Vendas – 19h</h3>
                        <a className="btn" href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/marketing-digital-direto-ao-ponto/" target="_blank" rel="noopener noreferrer">Marketing Digital Direto ao Ponto – 16h</a>
                        <a className="btn" href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/potencialize-suas-vendas/" target="_blank" rel="noopener noreferrer">Potencialize suas vendas – 3h</a>

                        <h3>Pessoas (RH) – 3h</h3>
                        <a className="btn" href="https://digital.sebraers.com.br/produtos/pessoas/curso/recrutamento-e-selecao-como-formar-um-time-vencedor/" target="_blank" rel="noopener noreferrer">Recrutamento e Seleção: como formar um time vencedor</a>
                    </div>

                    <div className="col">
                        <h3>Empreendedorismo – 2h</h3>
                        <a className="btn" href="https://digital.sebraers.com.br/produtos/empreendedorismo/curso/zoom-no-cliente-como-criar-valor-no-mercado/" target="_blank" rel="noopener noreferrer">ZOOM no cliente: como criar valor no mercado</a>

                        <h3>Mercado – 3h</h3>
                        <a className="btn" href="https://digital.sebraers.com.br/produtos/mercado/curso/economia-colaborativa-a-tendencia-que-pode-transformar-seu-negocio/" target="_blank" rel="noopener noreferrer">Economia Colaborativa - A tendência que pode transformar seu negócio</a>

                        <h3>Finanças – 12h</h3>
                        <a className="btn" href="https://digital.sebraers.com.br/produtos/financas/curso/programa-credcaxias-financas-para-pequenos-negocios/" target="_blank" rel="noopener noreferrer">Finanças para Pequenos Negócios – 6h</a>
                        <a className="btn" href="https://digital.sebraers.com.br/produtos/financas/curso/financas-empresariais-controlar-para-expandir/" target="_blank" rel="noopener noreferrer">Finanças empresariais: controlar para expandir – 6h</a>

                        <h3>Processos – 4h</h3>
                        <a className="btn" href="https://digital.sebraers.com.br/produtos/processos/curso/mapeando-o-fluxo-de-valor/" target="_blank" rel="noopener noreferrer">Mapeando o fluxo de valor: como identificar e reduzir perdas em seu processo</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BcxSectionD